/* ../styles/Pricing.css */

.pricing-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pricing-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333333;
  font-weight: 700;
  text-align: center;
}

.pricing-package {
  margin: 0 auto;
  margin-bottom: 40px;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  max-width: 800px;
}

.pricing-package:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-package h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
  font-weight: 700;
}

.pricing-package p {
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .pricing-package {
    padding: 20px;
  }
}
