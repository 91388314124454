.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 4rem;
  border-radius: 4px;
}

.form-container h2 {
  margin-top: 0;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.request-form {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
}

.form-field {
  margin-bottom: 1rem;
}

label {
  font-weight: 700;
  font-size: 16px;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  font-family: inherit;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Disables drag-ability */
  box-sizing: border-box; /* Includes padding in the width calculation */
}

textarea {
  height: 120px;
}

button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button:hover {
  background-color: #555;
}

.success-message {
  margin-top: 1rem;
  background-color: #d4edda;
  color: #155724;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  text-align: center; /* Add this property */
}

