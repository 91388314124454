/* Header.css */

body {
  margin: 0;
  padding: 0;
}

.header {
  background-color: #333;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}


@media (max-width: 768px) {
  .header {
    padding: 10px;
  }
}