.portfolio {
  margin-bottom: 20px;
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.portfolio-iframe {
  width: 100%;
  height: 500px;
  border: none;
  transition: transform 0.3s ease;
  transform: scale(0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.portfolio-iframe:hover {
  transform: scale(0.9);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 999px) {
  .portfolio-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.portfolio-title {
  font-size: 32px;
  margin-bottom: 0px;
  color: #333333;
  font-weight: 700;
  text-align: center;
}

