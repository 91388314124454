/* Banner.css */

.banner {
  background-color: #F98066;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.banner-text {
  color: #333;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
