/* Footer.css */

.footer {
  background-color: #333;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
}

.footer-content {
  text-align: center;
}

.footer-text {
  margin: 0;
  font-weight: 400;
}

.footer-text a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}
