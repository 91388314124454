/* ../styles/About.css */
#about {
  padding: 60px 20px;
  background-color: #f7f7f7;
}

#about .container {
  max-width: 800px;
  margin: 0 auto;
}

#about h2 {
  font-size: 32px;
  margin-bottom: 30px;
  color: #333333;
  font-weight: 700;
  text-align: center;
}

#about p {
  font-size: 18px;
  line-height: 1.6;
  color: #666666;
  margin-bottom: 20px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  #about {
    padding: 40px 20px;
  }

  #about h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  #about p {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
